import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import Layout from "../components/layout";
import Seo from "../components/seo";
import CartWidget from "../components/partials/cart-widget";
import states from "../data/states";
import CartContext from "../context/cart-context";
import CartTotals from "../components/partials/cart-totals";
import CartTotalsMobile from "../components/partials/cart-totals-mobile";
import CreditCardLogos from "../components/images/credit-card-logos";
import { Helmet } from "react-helmet";

const CheckoutStep2 = () => {
  const {
    checkoutInfo,
    contents,
    discounts,
    resetCart,
    setCheckoutInfo,
    setDiscountCode,
    totals,
  } = useContext(CartContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getCardLastFour = (transactionDetails) => {
    if (transactionDetails.card?.number) {
      return transactionDetails.card?.number?.substring(12);
    }

    if (transactionDetails.wallet?.card?.number) {
      return transactionDetails.wallet?.card?.number?.substring(12);
    }

    return "";
  };

  const handleSubmit = (transactionDetails) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.RECAPTCHA_SITE_KEY, {
          action: "checkout",
        })
        .then(async (token) => {
          const cookies =
            typeof document !== "undefined" ? document.cookie : [];

          const serverData = {
            ...checkoutInfo,
            dataValue: transactionDetails,
            amount: totals.total.substring(1),
            contents: contents,
            totals: totals,
            callInfo: checkoutInfo.callInfo
              ? JSON.parse(checkoutInfo.callInfo)
              : {},
            cookies,
            recaptchaToken: token,
            referralId: sessionStorage.getItem("referralId"),
          };
          serverData.cardLastFour = getCardLastFour(transactionDetails);
          if (process.env.NODE_ENV === "development") {
            // Simulate a successful response for development
            setIsSubmitting(false);
            navigate("/checkout-success/", {
              state: {
                transactionData: {}, // Fill with any mocked data you need
                amountCharged: totals.dueNow,
                tax: totals.tax,
                cartContents: contents,
                cartTotals: totals,
                checkoutInfo: checkoutInfo,
                email: checkoutInfo.email,
                callInfo: checkoutInfo.callInfo
                  ? JSON.parse(checkoutInfo.callInfo)
                  : {},
                discount: checkoutInfo.discountCode,
              },
            });
            resetCart();
          } else {
            axios
              .post(process.env.PAYMENT_CAPTURE_URL, serverData)
              .then((chargeResponse) => {
                setIsSubmitting(false);
                if (
                  chargeResponse.data.success ||
                  chargeResponse.data.data.responsetext === "Approved"
                ) {
                  navigate("/checkout-success/", {
                    state: {
                      transactionData: chargeResponse.data.data,
                      amountCharged: totals.total,
                      tax: totals.tax,
                      cartContents: contents,
                      discount: discounts,
                      cartTotals: totals,
                      checkoutInfo: checkoutInfo,
                      email: checkoutInfo.email,
                      callInfo: checkoutInfo.callInfo
                        ? JSON.parse(checkoutInfo.callInfo)
                        : {},
                    },
                  });
                  resetCart();
                } else {
                  alert(chargeResponse.data.data.responsetext);
                }
              })
              .catch((error) => {
                alert(
                  `We are having an issue contacting our payment provider. Please call us to place your order. ${process.env.PHONE_NUMBER}`
                );
                setIsSubmitting(false);
              });
          }
        });
    });
  };

  const [state, setState] = useState({
    useDifferentAddress: false,
    shippingZip: checkoutInfo.shippingZip,
    shippingAddress: checkoutInfo.shippingAddress,
    shippingMethod: checkoutInfo.shippingMethod,
    billingFirstName: checkoutInfo.firstName,
    billingLastName: checkoutInfo.lastName,
    billingAddress: checkoutInfo.shippingAddress,
    billingAddress2: checkoutInfo.shippingAddress2,
    billingCity: checkoutInfo.shippingCity,
    billingState: checkoutInfo.shippingState,
    billingZip: checkoutInfo.shippingZip,
    billingNotes: "",
    discountCode: checkoutInfo.discountCode || "",
    transactionDetails: {},
    ccnumberValid: false,
    ccexpValid: false,
    cvvValid: false,
    showDebugInfo: false,
  });

  useEffect(() => {
    if (!checkoutInfo) return;

    if (typeof sessionStorage !== "undefined") {
      axios
        .post(`/.netlify/functions/start-checkout`, {
          email: checkoutInfo.email,
          checkoutInfo: checkoutInfo,
          cartTotals: totals,
          cartContents: contents,
          sourceUrl: sessionStorage.getItem("bpd-first-page"),
          referrer: sessionStorage.getItem("bpd-referrer"),
          value: totals?.total ? +totals.total?.substr(1) : 0,
          gclid: localStorage.getItem("bpd-gclid"),
          fbclid: localStorage.getItem("bpd-fbclid"),
          utm_source: localStorage.getItem("utm_source"),
          utm_medium: localStorage.getItem("utm_medium"),
          utm_campaign: localStorage.getItem("utm_campaign"),
          msclkid: localStorage.getItem("msclkid"),
        })
        .then((response) => {
          if (response.data?.klaviyo?.cart_id) {
            localStorage.setItem(
              "bpd-cart-id",
              response.data?.klaviyo?.cart_id
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [checkoutInfo, totals, contents]);

  useEffect(() => {
    if (!state.shippingZip) {
      navigate("/checkout/");
    }
    if (!state.shippingAddress) {
      navigate("/checkout/");
    }

    const lineItems = contents.map((item) => {
      return {
        label: item.title,
        amount: (item.salePrice || item.basePrice).toFixed(2),
      };
    });

    if (typeof window !== "undefined" && totals.total) {
      const collectJSConfig = {
        callback: paymentCallback,
        paymentSelector: "#payButton",
        variant: "inline",
        googleFont: "Abel",
        invalidCss: {
          color: "#B40E3E",
        },
        validCss: {
          color: "#13AA73",
        },
        customCss: {
          "border-color": "#ffffff",
          "border-style": "solid",
        },
        focusCss: {
          "border-color": "#1AD18E",
          "border-style": "solid",
          "border-width": "3px",
        },
        fields: {
          ccnumber: {
            selector: "#ccnumber",
            title: "Card Number",
            placeholder: "0000 0000 0000 0000",
          },
          ccexp: {
            selector: "#ccexp",
            title: "Card Expiration",
            placeholder: "mm/yy",
          },
          cvv: {
            display: "show",
            selector: "#cvv",
            title: "CVV Code",
            placeholder: "***",
          },
          googlePay: {
            selector: "#googlePayButton",
            shippingAddressRequired: false,
            shippingAddressParameters: {
              phoneNumberRequired: false,
              allowedCountryCodes: ["US"],
            },
            billingAddressRequired: true,
            billingAddressParameters: {
              phoneNumberRequired: false,
              format: "MIN",
            },
            emailRequired: true,
            buttonType: "buy",
            buttonColor: "default",
            buttonLocale: "en",
          },
          applePay: {
            selector: "#applePayButton",
            requiredBillingContactFields: ["postalAddress", "name"],
            requiredShippingContactFields: ["postalAddress", "name"],
            contactFields: ["phone", "email"],
            contactFieldsMappedTo: "shipping",
            lineItems,
            shippingMethods: [
              {
                label: "Selected Previously",
                amount: "0.00",
                detail: "The shipping method you selected previously",
                identifier: "standardShipping",
              },
            ],
            totalLabel: "Total",
            totalType: "pending",
            type: "buy",
            style: {
              "button-style": "white-outline",
              height: "50px",
              "border-radius": "0",
            },
          },
        },
        price: totals.total.substring(1),
        currency: "USD",
        country: "US",
        validationCallback: validationCallback,
        timeoutDuration: 3000,
        timeoutCallback: timeoutCallback,
      };

      window.CollectJS.configure(collectJSConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals.total]);

  const paymentCallback = (response) => {
    console.log("response", response);
    setState((s) => ({ ...s, transactionDetails: response }));
    if (response.token) {
      handleSubmit(response);
    } else {
      alert(
        "There is a problem with your card. Please check the info and try again"
      );
      setIsSubmitting(false);
    }
  };

  const validationCallback = (field, status, message) => {
    const stateKey = `${field}Valid`;
    setState((s) => ({ ...s, [stateKey]: status }));
  };

  const timeoutCallback = () => {
    alert(
      `We're having an issue processing your card. Please check your payment details. To place your order by phone call us at ${process.env.PHONE_NUMBER}`
    );
    setIsSubmitting(false);
  };

  const toggleAddressType = (e) => {
    const checkoutInfo =
      typeof window !== `undefined`
        ? JSON.parse(localStorage.getItem("cartValues")).checkoutInfo
        : {};

    const useDifferentAddress = e.target.checked || false;

    setState((s) => ({
      ...s,
      useDifferentAddress: useDifferentAddress,
    }));

    if (useDifferentAddress) {
      setState((s) => ({
        ...s,
        billingFirstName: "",
        billingLastName: "",
        billingAddress: "",
        billingAddress2: "",
        billingCity: "",
        billingState: "",
        billingZip: "",
      }));
    } else {
      setState((s) => ({
        ...s,
        billingFirstName: checkoutInfo.firstName,
        billingLastName: checkoutInfo.lastName,
        billingAddress: checkoutInfo.shippingAddress,
        billingAddress2: checkoutInfo.shippingAddress2,
        billingCity: checkoutInfo.shippingCity,
        billingState: checkoutInfo.shippingState,
        billingZip: checkoutInfo.shippingZip,
      }));
    }
  };

  const checkAddress = () => {
    if (!state.useDifferentAddress) {
      return true;
    }

    if (
      !state.billingFirstName ||
      !state.billingLastName ||
      !state.billingAddress ||
      !state.billingCity ||
      !state.billingState ||
      !state.billingZip
    ) {
      return false;
    }
    return true;
  };

  const canSubmit = () => {
    if (
      isSubmitting ||
      !checkAddress() ||
      !state.ccnumberValid ||
      !state.ccexpValid ||
      !state.cvvValid
    ) {
      return false;
    }
    return true;
  };

  return (
    <Layout>
      <Seo title="Checkout Step 2" />
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`}
        ></script>
      </Helmet>
      <CartTotalsMobile />
      <div className="container px-4 mx-auto my-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-3/5 lg:pr-6">
            <h1 className="font-xxl mb-8">Billing Information</h1>
            <Formik
              initialValues={{
                billingFirstName: state.billingFirstName,
                billingLastName: state.billingLastName,
                billingAddress: state.billingAddress,
                billingAddress2: state.billingAddress2,
                billingCity: state.billingCity,
                billingState: state.billingState,
                billingZip: state.billingZip,
                billingNotes: state.billingNotes,
                discountCode: state.discountCode,
              }}
              validate={(values) => {
                setState((s) => ({
                  ...s,
                  billingFirstName: values.billingFirstName,
                  billingLastName: values.billingLastName,
                  billingAddress: values.billingAddress,
                  billingAddress2: values.billingAddress2,
                  billingCity: values.billingCity,
                  billingState: values.billingState,
                  billingZip: values.billingZip,
                }));
                let errors = {};
                if (!values.billingFirstName) {
                  errors.billingFirstName = "Required";
                }
                if (!values.billingLastName) {
                  errors.billingLastName = "Required";
                }
                if (!values.billingAddress) {
                  errors.billingAddress = "Required";
                }
                if (!values.billingCity) {
                  errors.billingCity = "Required";
                }
                if (!values.billingState) {
                  errors.billingState = "Required";
                }
                if (!values.billingZip) {
                  errors.billingZip = "Required";
                }

                setCheckoutInfo({
                  ...checkoutInfo,
                  referrer: sessionStorage.getItem("bpd-referrer"),
                  firstPage: sessionStorage.getItem("bpd-first-page"),
                  gclid: localStorage.getItem("bpd-gclid"),
                  fbclid: localStorage.getItem("bpd-fbclid"),
                  utm_source: localStorage.getItem("bpd-utm_source"),
                  utm_medium: localStorage.getItem("bpd-utm_medium"),
                  utm_campaign: localStorage.getItem("bpd-utm_campaign"),
                  msclkid: localStorage.getItem("bpd-msclkid"),
                  marketingCampaignInfo: localStorage.getItem(
                    "marketingCampaignInfo"
                  ),
                  taxRate: process.env.TAX_RATE,
                  discountInformation: sessionStorage.getItem("discounts"),
                  ...values,
                });

                return errors;
              }}
              onSubmit={(values) => {
                setCheckoutInfo((ci) => ({
                  ...ci,
                  ...values,
                }));
                handleSubmit();
              }}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    <div
                      className={isSubmitting ? "submitting-payment" : "hidden"}
                    >
                      Securely processing order details...
                    </div>
                    <div
                      className={state.useDifferentAddress ? "hidden" : "mb-4"}
                    >
                      <h4 className="text-grey-darkest">
                        {state.billingFirstName} {state.billingLastName}
                      </h4>
                      <p className="text-grey-darkest">
                        {state.billingAddress}
                        <br />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: state.billingAddress2
                              ? `${state.billingAddress2}<br />`
                              : "",
                          }}
                        />
                        {state.billingCity}, {state.billingState}{" "}
                        {state.billingZip}
                      </p>
                    </div>
                    <div>
                      <div className="flex flex-wrap w-full items-stretch">
                        <div className="w-full mb-6">
                          <label
                            htmlFor="useDifferentBillingAddress"
                            className="block mb-2"
                          >
                            <strong>Use a different billing address</strong>{" "}
                            <abbr className="required" title="Required">
                              *
                            </abbr>
                            <input
                              checked={!!state.useDifferentAddress}
                              value={!!state.useDifferentAddress}
                              className="ml-4 px-12 focus:outline-none focus:shadow"
                              id="useDifferentBillingAddress"
                              name="useDifferentBillingAddress"
                              onChange={toggleAddressType}
                              type="checkbox"
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${
                          !state.useDifferentAddress
                            ? "hidden"
                            : "flex flex-wrap"
                        } w-full items-stretch`}
                      >
                        <div className="mb-6 w-1/2 pr-2">
                          <label
                            htmlFor="billingFirstName"
                            className="block mb-2"
                          >
                            <strong>First Name</strong>{" "}
                            <abbr className="required" title="Required">
                              *
                            </abbr>
                          </label>
                          <Field
                            type="text"
                            name="billingFirstName"
                            id="billingFirstName"
                            placeholder="First Name"
                            className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                          />
                          <ErrorMessage
                            className="text-red-dark text-sm mt-2"
                            name="billingFirstName"
                            component="div"
                          />
                        </div>
                        <div className="mb-6 w-1/2 pr-2">
                          <label
                            htmlFor="billingLastName"
                            className="block mb-2"
                          >
                            <strong>Last Name</strong>{" "}
                            <abbr className="required" title="Required">
                              *
                            </abbr>
                          </label>
                          <Field
                            type="text"
                            name="billingLastName"
                            id="billingLastName"
                            placeholder="Last Name"
                            className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                          />
                          <ErrorMessage
                            className="text-red-dark text-sm mt-2"
                            name="billingLastName"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        !state.useDifferentAddress ? "hidden" : "mb-16"
                      }
                    >
                      <h3 className="mt-12 mb-4 pb-2 border-b">
                        Billing Address
                      </h3>

                      <Field
                        type="text"
                        name="billingAddress"
                        placeholder="Billing Address"
                        className="shadow appearance-none border border-grey-light rounded w-full mb-4 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                      />
                      <ErrorMessage
                        className="text-red-dark text-sm mt-2"
                        name="billingAddress"
                        component="div"
                      />

                      <div className="mb-6">
                        <Field
                          type="text"
                          name="billingAddress2"
                          placeholder="Line 2"
                          className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                        />
                        <ErrorMessage
                          className="text-red-dark text-sm mt-2"
                          name="billingAddress2"
                          component="div"
                        />
                      </div>

                      <div className="flex flex-wrap w-full items-stretch">
                        <div className="mb-6 w-1/2 pr-2">
                          <label htmlFor="billingCity" className="block mb-2">
                            <strong>City</strong>{" "}
                            <abbr className="required" title="Required">
                              *
                            </abbr>
                          </label>
                          <Field
                            type="text"
                            name="billingCity"
                            placeholder="City"
                            className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                          />
                          <ErrorMessage
                            className="text-red-dark text-sm mt-2"
                            name="billingCity"
                            component="div"
                          />
                        </div>

                        <div className="mb-6 w-1/2 pl-2">
                          <label htmlFor="billingState" className="block mb-2">
                            <strong>State</strong>{" "}
                            <abbr className="required" title="Required">
                              *
                            </abbr>
                          </label>
                          <Field
                            component="select"
                            name="billingState"
                            className="bg-white shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                          >
                            <option value="" aria-label="N/A" key="na" />
                            {states.map((state, index) => {
                              return (
                                <option value={state.abbreviation} key={index}>
                                  {state.name}
                                </option>
                              );
                            })}
                          </Field>
                          <ErrorMessage
                            className="text-red-dark text-sm mt-2"
                            name="billingState"
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="mb-6">
                        <div className="mb-6 w-1/2 pr-2">
                          <label htmlFor="billingZip" className="block mb-2">
                            <strong>Zip</strong>{" "}
                            <abbr className="required" title="Required">
                              *
                            </abbr>
                          </label>
                          <Field
                            type="text"
                            name="billingZip"
                            placeholder="84000"
                            className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                          />
                          <ErrorMessage
                            className="text-red-dark text-sm mt-2"
                            name="billingZip"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap items-end">
                      <div className="mb-6 w-1/2 pr-2">
                        <label htmlFor="discountCode" className="block mb-2">
                          <strong>Discount Code</strong>
                        </label>
                        <div className="relative">
                          <Field
                            type="text"
                            name="discountCode"
                            placeholder=""
                            className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                setDiscountCode(values.discountCode);
                              }
                            }}
                          />
                          {values.discountCode && (
                            <button
                              className="absolute p-1 text-red"
                              onClick={() => {
                                setFieldValue("discountCode", "");
                                setDiscountCode("");
                              }}
                              style={{
                                height: "30px",
                                width: "30px",
                                right: "6px",
                                top: "6px",
                              }}
                              type="button"
                            >
                              x
                            </button>
                          )}
                        </div>
                        <ErrorMessage
                          className="text-red-dark text-sm mt-2"
                          name="discountCode"
                          component="div"
                        />
                      </div>
                      {discounts?.code !== values.discountCode ? (
                        <div className="mb-6 w-1/2 pr-2">
                          {values.discountCode && (
                            <button
                              className={`block md:inline-block bg-blue text-white text-sm px-4 py-3 no-underline rounded uppercase ${
                                !values.discountCode ? "opacity-50" : ""
                              }`}
                              type="button"
                              disabled={!values.discountCode}
                              onClick={() => {
                                setDiscountCode(values.discountCode);
                              }}
                            >
                              Apply
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="mb-6 w-1/2 pr-2 pb-3 text-sm">
                          Code{" "}
                          <code className="py-1 px-2 bg-grey-lightest border rounded">
                            {discounts?.code}
                          </code>{" "}
                          applied
                        </div>
                      )}
                    </div>

                    <div className="bg-grey-lighter p-4 mb-4">
                      <div className="flex mb-4 mt-4 pb-2 border-b border-grey items-end justify-between">
                        <div className="flex justify-start">
                          <h3>Credit Card</h3>

                          <svg
                            className="ml-1 lg:ml-3 heroicon-lock heroicon heroicon-sm"
                            height="17"
                            onDoubleClick={() =>
                              setState((s) => ({
                                ...s,
                                showDebugInfo: !state.showDebugInfo,
                              }))
                            }
                            viewBox="0 0 60 60"
                            width="17"
                            xmlns="https://www.w3.org/2000/svg"
                          >
                            <rect
                              className="heroicon-lock-body heroicon-component-fill"
                              width="54"
                              height="34"
                              x="3"
                              y="25"
                            />
                            <path
                              className="heroicon-lock-top heroicon-component-accent heroicon-component-fill"
                              d="M12 25v-6a18 18 0 1 1 36 0v6h9v5H3v-5h9zm6 0h24v-6a12 12 0 1 0-24 0v6z"
                            />
                            <path
                              className="heroicon-shadows"
                              d="M45 25v-6a15 15 0 0 0-30 0v6h3v-6a12 12 0 1 1 24 0v6.33l3-.33z"
                            />
                            <path
                              className="heroicon-outline"
                              fillRule="nonzero"
                              d="M11 24v-5a19 19 0 0 1 38 0v5h9v36H2V24h9zm0 2H4v3h25v-3H11zm18 5H4v3h25v-3zM4 38h25v-3H4v3zm0 1v3h25v-3H4zm0 7h25v-3H4v3zm0 1v3h25v-3H4zm0 7h25v-3H4v3zm0 1v3h25v-3H4zm27 0v3h25v-3H31zm25-1v-3H31v3h25zm0-7H31v3h25v-3zm0-1v-3H31v3h25zm0-7H31v3h25v-3zm0-1v-3H31v3h25zm0-7H31v3h25v-3zm0-2v-3H31v3h25zm-15-5v-5a11 11 0 0 0-22 0v5h22zm2 0h4v-5a17 17 0 1 0-34 0v5h4v-5a13 13 0 0 1 26 0v5z"
                            />
                          </svg>
                        </div>
                        <CreditCardLogos />
                      </div>

                      <div className="flex flex-wrap items-center gap-2">
                        <div id="applePayButton"></div>
                        <div id="googlePayButton"></div>
                      </div>

                      <div className="my-6">
                        <label htmlFor="ccnumber" className="block mb-2">
                          <strong>Credit Card Number</strong>{" "}
                          <abbr className="required" title="Required">
                            *
                          </abbr>
                        </label>
                        <div
                          className="paymentField bg-white shadow appearance-none border border-grey rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                          id="ccnumber"
                        ></div>
                      </div>

                      <div className="flex flex-wrap w-full items-stretch">
                        <div className="mb-6 w-1/2 pr-2">
                          <label htmlFor="ccexp" className="block mb-2">
                            <strong>Expiration</strong>{" "}
                            <abbr className="required" title="Required">
                              *
                            </abbr>
                          </label>
                          <div
                            className="paymentField bg-white shadow appearance-none border border-grey rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                            id="ccexp"
                          ></div>
                        </div>

                        <div className="mb-6 w-1/2 pl-2">
                          <label htmlFor="cvv" className="block mb-2">
                            <strong>CVC Number</strong>{" "}
                            <abbr className="required" title="Required">
                              *
                            </abbr>
                          </label>
                          <div
                            className="paymentField bg-white shadow appearance-none border border-grey rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                            id="cvv"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="my-6">
                      <label htmlFor="billingNotes" className="block mb-2">
                        <strong>Billing Notes</strong>{" "}
                      </label>
                      <Field
                        component="textarea"
                        type="textarea"
                        name="billingNotes"
                        className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-32"
                      />
                      <ErrorMessage
                        className="text-red-dark text-sm mt-2"
                        name="billingNotes"
                        component="div"
                      />
                    </div>

                    <div className="mb-6 flex justify-between">
                      <Link
                        to="/checkout/"
                        className="block md:inline-block bg-grey text-white text-sm px-4 py-3 no-underline rounded uppercase"
                      >
                        Previous Step
                      </Link>
                      <button
                        id="payButton"
                        type="submit"
                        title={
                          canSubmit()
                            ? "Submit my payment"
                            : "You are missing at least one required field"
                        }
                        onClick={(e) => {
                          setIsSubmitting(true);
                        }}
                        className={`${
                          !canSubmit() ? "hidden" : "block md:inline-block"
                        } bg-${
                          process.env.THEME_COLOR_PRIMARY
                        }-dark text-white text-sm px-4 py-3 no-underline rounded uppercase`}
                      >
                        {isSubmitting ? "Placing order" : "Submit Payment"}
                      </button>
                      <button
                        className={`${
                          canSubmit() ? "hidden" : "block md:inline-block"
                        } opacity-50 bg-${
                          process.env.THEME_COLOR_PRIMARY
                        } text-white text-sm px-4 py-3 no-underline rounded uppercase`}
                        onClick={(e) => {
                          e.preventDefault();
                          alert("Please fill out all of the required fields");
                        }}
                        onKeyDown={(e) => {
                          e.preventDefault();
                          alert("Please fill out all of the required fields");
                        }}
                        title="You are missing at least one required field"
                        type="button"
                      >
                        Submit Payment
                      </button>
                    </div>
                    {state.showDebugInfo && (
                      <div>
                        <p>
                          ccnumberValid: {state.ccnumberValid ? "yes" : "no"}
                        </p>
                        <p>ccexpValid: {state.ccexpValid ? "yes" : "no"}</p>
                        <p>cvvValid: {state.cvvValid ? "yes" : "no"}</p>
                        <p>
                          addressValid: {checkAddress(false) ? "yes" : "no"}
                        </p>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="w-full md:w-2/5 lg:pl-6">
            <CartWidget hideButtons={true} hideYouMayAlsoLike={true} />
            <CartTotals />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CheckoutStep2;
